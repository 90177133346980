import React from 'react';
import './App.css';
import JobApplicationForm from './JobApplicationForm';
import { BrowserRouter as Router, Route,Routes  } from 'react-router-dom';
import QRCodeGenerator from './QrCodeGenerator';

//import ParticlesComponent from './particle.js/ParticlesComponent';
//require('dotenv').config();


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<JobApplicationForm />} />
        <Route path="/qrcode-generator" element={<QRCodeGenerator />} />
      </Routes>
    </Router>
  );
}

export default App;
