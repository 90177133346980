import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import logoImage from './yatay-logo.png'; // Logo resminizin yolunu doğru bir şekilde belirtin


function EventQRCodeGenerator() {
  const [eventData, setEventData] = useState([{ id: Date.now(), name: '' }]);

  const handleChange = (event, id) => {
    const updatedEventData = eventData.map(data => {
        if (data.id === id) {
            // Türkçe karakterleri İngilizce karakterlere dönüştür
            const englishName = event.target.value
              .replace(/ğ/g, 'g')
              .replace(/Ğ/g, 'G')
              .replace(/ü/g, 'u')
              .replace(/Ü/g, 'U')
              .replace(/ş/g, 's')
              .replace(/Ş/g, 'S')
              .replace(/ı/g, 'i')
              .replace(/İ/g, 'I')
              .replace(/ö/g, 'o')
              .replace(/Ö/g, 'O')
              .replace(/ç/g, 'c')
              .replace(/Ç/g, 'C')
              .replace(/\s+/g, '-') // Boşlukları tireye dönüştür
              .replace(/[^a-zA-Z0-9\-]/g, ''); // İzin verilen karakterler dışındakileri kaldır
            return { ...data, name: englishName };
          }
          return data;
        });
    setEventData(updatedEventData);
  };

  const handleAddQRCode = () => {
    setEventData([...eventData, { id: Date.now(), name: '' }]);
  };

  const handleRemoveQRCode = (id) => {
    const updatedEventData = eventData.filter(data => data.id !== id);
    setEventData(updatedEventData);
  };

  const generateQRText = (eventName) => {
    return `Etkinlik Adı: ${eventName}`;
  };

  const downloadQRCode = (event) => {
    const canvas = document.getElementById(`qr-canvas-${event.id}`);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `QR_Code_${event.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <h2>Etkinlik QR Kodu Oluşturucu</h2>
      {eventData.map(event => (
        <div key={event.id} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            value={event.name}
            onChange={(e) => handleChange(e, event.id)}
            placeholder="Etkinlik Adı Giriniz"
            style={{ marginRight: '10px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
          <button onClick={() => downloadQRCode(event)} style={{ padding: '5px 7px', borderRadius: '4px', background: '#4a3f92', color: '#fff', border: 'none', cursor: 'pointer' }}>QR Kodu İndir</button>
          <button onClick={() => handleRemoveQRCode(event.id)} style={{ margin:'10px', padding: '5px 7px', borderRadius: '4px', background: '#4a3f92', color: '#fff', border: 'none', cursor: 'pointer' }}>Kodu Kaldır</button>
          <QRCode id={`qr-canvas-${event.id}`} value={"https://ik-basvuru.fabitech.com.tr?"+generateQRText(event.name)} logo={logoImage} />
          
        </div>
      ))}
      <button onClick={handleAddQRCode} style={{ padding: '10px 20px', borderRadius: '4px', background: '#007bff', color: '#fff', border: 'none', cursor: 'pointer' }}>Yeni QR Kodu Ekle</button>
    </div>
  );
}

export default EventQRCodeGenerator;
